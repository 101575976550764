import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c7b58dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch-table-root" }
const _hoisted_2 = { class: "tab-menu-group" }
const _hoisted_3 = { class: "switch-table-list-wrap" }
const _hoisted_4 = { class: "switch-table-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_switch_table_item = _resolveComponent("switch-table-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["tab-item", { active: _ctx.tabId === 1 }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.switchTab(1)), ["prevent"]))
      }, _toDisplayString(_ctx.t('loby.all')), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-item", { active: _ctx.tabId === 2 }]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.switchTab(2)), ["prevent"]))
      }, _toDisplayString(_ctx.t('common.baccarat')), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["tab-item", { active: _ctx.tabId === 3 }]),
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.switchTab(3)), ["prevent"]))
      }, _toDisplayString(_ctx.t('common.dragontiger')), 3)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredDesk, (desk) => {
          return (_openBlock(), _createBlock(_component_switch_table_item, {
            class: "switch-table-list-item",
            key: desk.id,
            activeDesk: -1,
            mData: desk,
            onClick: _withModifiers(($event: any) => (_ctx.$emit('enter-table', desk.id, desk)), ["prevent"])
          }, null, 8, ["mData", "onClick"]))
        }), 128))
      ])
    ])
  ]))
}